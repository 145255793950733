import landingPage from "../../../images/Projects/Sarouj/backgroundImage.jpeg";
import goldlogo from "../../../images/Projects/Sarouj/sarouj-gold-logo.png";
import logo from "../../../images/Projects/sarouj-logo.png";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
import { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../../../index.css";
import { Navigation } from "swiper/modules";
import slide1 from "../../../images/Projects/Sarouj/Slide-1.jpg";
import slide2 from "../../../images/Projects/Sarouj/Slide-2.jpg";
import slide3 from "../../../images/Projects/Sarouj/Slide-3.jpg";
import slide4 from "../../../images/Projects/Sarouj/Slide-4.jpg";
import slide5 from "../../../images/Projects/Sarouj/Slide-5.jpg";
import slide6 from "../../../images/Projects/Sarouj/Slide-6.jpg";
import slide7 from "../../../images/Projects/Sarouj/Slide-7.jpg";
import slide8 from "../../../images/Projects/Sarouj/slide1-commercial.jpeg";
import slide9 from "../../../images/Projects/Sarouj/slide2-commercial.jpeg";
import villaPin from "../../../images/Projects/Sarouj/villas-pin.png";
import commercialPin from "../../../images/Projects/Sarouj/commercial-building-pin.png";
import residentialPin from "../../../images/Projects/Sarouj/residential-apartment-pin.png";
import townHousePin from "../../../images/Projects/Sarouj/town-houses-pin.png";
import vid1 from "../../../images/Projects/Sarouj/villas-video.mp4";
import vid2 from "../../../images/Projects/Sarouj/town-house-video.mp4";
import vid3 from "../../../images/Projects/Sarouj/residential-house-video.mp4";
import phone from "../../../images/Projects/Sarouj/background.jpeg";

function Sarooj() {
  const [isIframeVisible, setIsIframeVisible] = useState(false);
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const [showSliderCommercial, setShowSliderCommercial] = useState(false);
  const [showTownHouses, setShowTownHouses] = useState(false);
  const [showResidential, setShowResidential] = useState(false);
  const [showVillas, setShowVillas] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");
  const [videoSrc, setVideoSrc] = useState("");
  const sliderRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleIframeToggle = (url) => {
    setIframeSrc(url);
    setIsIframeVisible(!isIframeVisible);
    handleGlobalClick();
  };
  const handleVideo = (src) => {
    setVideoSrc(src);
    setIsVideoVisible(!isVideoVisible);
    handleGlobalClick();
  };
  const handleShowSlide = () => {
    setShowSlider(true);
    handleGlobalClick();
  };
  const handleShowSlideCommercial = () => {
    setShowSliderCommercial(true);
    handleGlobalClick();
  };

  const handleClickOutside = (e) => {
    if (sliderRef.current && !sliderRef.current.contains(e.target)) {
      setShowSlider(false);
      setShowSliderCommercial(false);
    }
  };

  const handleGlobalClick = () => {
    if (showVillas) setShowVillas(false);
    if (showTownHouses) setShowTownHouses(false);
    if (showResidential) setShowResidential(false);
  };

  useEffect(() => {
    if (showSlider || showSliderCommercial) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSlider, showSliderCommercial]);
  return (
    <div className="w-[fit-content]">
      {/* Header Section */}
      <div className="z-[2] w-[100%] lg:px-[4%] fixed pt-[2%]">
        <div className=" flex  justify-between items-start lg:items-end">
          <div className=" flex justify-evenly items-center w-[100%] text-[22px] xl:text-[15px] lg:hidden ">
            <img
              className="w-[60px] mt-[-2%] xl:w-[50px]"
              src={goldlogo}
              alt="logo"
            />
            <div className="relative text-center">
              <Link
                onClick={() => {
                  setShowTownHouses(true);
                }}
                className="  hover:text-[#9b2e2e]  text-white font-[700]   cairo-font"
              >
                TOWN HOUSES
              </Link>
              {showTownHouses && (
                <div className=" absolute flex flex-col w-[100%] py-[3%] text-[18px] xl:text-[12px]">
                  <Link
                    onClick={() =>
                      handleIframeToggle(
                        "https://my.matterport.com/show/?m=dHmdWsQoY6o"
                      )
                    }
                    className="text-white hover:text-[#9b2e2e]"
                  >
                    3D Tour
                  </Link>
                  <Link
                    className="text-white hover:text-[#9b2e2e] pt-[3%]"
                    onClick={() => handleVideo(vid2)}
                  >
                    Video
                  </Link>
                </div>
              )}
            </div>
            <div className="relative text-center">
              <Link
                onClick={() => {
                  setShowVillas(true);
                }}
                className="  hover:text-[#9b2e2e]  text-white font-[700]   cairo-font"
              >
                VILLAS
              </Link>
              {showVillas && (
                <div className=" absolute flex flex-col w-[100%] py-[3%] text-[18px] xl:text-[12px]">
                  <Link
                    onClick={() =>
                      handleIframeToggle(
                        "https://my.matterport.com/show/?m=ZWKSeA4bvZu"
                      )
                    }
                    className="text-white hover:text-[#9b2e2e]"
                  >
                    3D Tour
                  </Link>
                  <Link
                    className="text-white hover:text-[#9b2e2e] pt-[3%]"
                    onClick={() => handleVideo(vid1)}
                  >
                    Video
                  </Link>
                </div>
              )}
            </div>
            <div className="relative text-center">
              <Link
                onClick={() => {
                  setShowResidential(true);
                }}
                className="  hover:text-[#9b2e2e]  text-white font-[700]   cairo-font"
              >
                RESIDENTIAL
              </Link>
              {showResidential && (
                <div className=" absolute flex flex-col w-[100%] py-[3%] text-[18px] xl:text-[12px]">
                  <Link
                    onClick={() =>
                      handleIframeToggle(
                        "https://my.matterport.com/show/?m=QhyJe1Cnzez"
                      )
                    }
                    className="text-white hover:text-[#9b2e2e]"
                  >
                    3D Tour
                  </Link>
                  <Link
                    className="text-white hover:text-[#9b2e2e] pt-[3%]"
                    onClick={() => handleVideo(vid3)}
                  >
                    Video
                  </Link>
                </div>
              )}
            </div>

            <Link
              onClick={() => handleShowSlideCommercial()}
              className="  hover:text-[#9b2e2e] cairo-font text-white font-[700] "
            >
              COMMERCIAL
            </Link>
            <Link
              onClick={() => handleShowSlide()}
              className="  hover:text-[#9b2e2e] cairo-font text-white font-[700] "
            >
              RENDERS
            </Link>
            <div className=" flex justify-center  xl:h-[100px] h-[120px] flex-col items-center">
              <img className="w-[200px] xl:w-[150px]" src={logo} alt="logo" />

              <Link
                to="https://forms.gle/tksHNX2NXPD42LPS8"
                target="_blank"
                className="text-white text-[15px] xl:text-[12px] hover:text-[#aa3131]  pt-[3%] font-[700] underline-offset-1 underline"
              >
                Register Your Interest
              </Link>
            </div>
          </div>

          <div className="hidden lg:flex text-white   w-[100%] justify-between items-center pt-[2%]">
            <img
              className="w-[60px] mt-[-2%] xl:w-[50px]"
              src={goldlogo}
              alt="logo"
            />
            <div className="hidden lg:flex items-center">
              {menuOpen ? (
                <MdOutlineClose
                  onClick={toggleMenu}
                  style={{ fontSize: 30, color: "white", zIndex: "500" }}
                />
              ) : (
                <FaBars
                  onClick={toggleMenu}
                  style={{ fontSize: 30, color: "white", zIndex: "500" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Background Image Section */}
      <div className=" w-[100vw] h-[100vh]  overflow-auto relative">
        <div
          className="absolute inset-0"
          onClick={() => {
            handleGlobalClick();
          }}
        >
          <img
            className="w-full relative h-full lg:hidden "
            src={landingPage}
            alt="Sarooj landing page"
          />
          <img
            className="w-[100%] h-[100%] object-cover hidden lg:block"
            src={phone}
          />
        </div>
        <img
          alt=""
          onClick={() =>
            handleIframeToggle("https://my.matterport.com/show/?m=dHmdWsQoY6o")
          }
          src={townHousePin}
          className="w-[70px] cursor-pointer bounce lg:hidden  top-[45%]  left-[22%] absolute"
        />
        <img
          alt=""
          onClick={() =>
            handleIframeToggle("https://my.matterport.com/show/?m=ZWKSeA4bvZu")
          }
          src={villaPin}
          className="w-[70px] cursor-pointer top-[50%] right-[41%] absolute bounce lg:hidden"
        />

        <img
          alt=""
          onClick={() =>
            handleIframeToggle("https://my.matterport.com/show/?m=QhyJe1Cnzez")
          }
          src={residentialPin}
          className="w-[120px] 2xl:w-[100px] cursor-pointer bounce lg:hidden  top-[38%] right-[35%] absolute"
        />
        <img
          alt=""
          onClick={() => handleShowSlideCommercial()}
          src={commercialPin}
          className="w-[120px] 2xl:w-[100px] cursor-pointer bounce lg:hidden  top-[33%] right-[13%] absolute"
        />
        {/* Clickable Links */}

        {/* Centered Iframe */}
        {isIframeVisible && (
          <div
            onClick={handleIframeToggle}
            className="fixed inset-0 flex pt-[4%] items-center justify-center z-[100] bg-[rgba(0,0,0,0.5)]"
          >
            <iframe
              src={iframeSrc}
              title="Virtual Tour"
              className="w-[65vw] h-[75vh] xl:w-[80vw] lg:h-[70vh] "
              sandbox="allow-scripts allow-same-origin"
            ></iframe>
          </div>
        )}
      </div>
      <div className="bg-[#c5c5c56e] absolute top-[40%] py-[2%] w-[100%] hidden lg:flex flex-col justify-center items-center">
        <div className="w-[60%]">
          <img src={logo} alt="" className="w-[100%] h-[100%] object-cover" />
        </div>

        <Link
          to="https://forms.gle/tksHNX2NXPD42LPS8"
          target="_blank"
          className="text-white text-[4vw] hover:text-[#aa3131]  pt-[3%] font-[700] underline-offset-1 underline"
        >
          Register Your Interest
        </Link>
      </div>
      {isVideoVisible && (
        <div
          onClick={handleVideo}
          className="fixed inset-0 flex pt-[2%] items-center justify-center z-[100] bg-[rgba(0,0,0,0.5)]"
        >
          <video
            src={videoSrc}
            title="Video Tour"
            className="w-[70vw] lg:w-[90%]"
            controls
            autoPlay
          ></video>
        </div>
      )}

      {showSlider && (
        <div className="fixed inset-0  flex items-center justify-center bg-[rgba(0,0,0,0.5)] z-[20]">
          {/* Ref added to track clicks inside the slider */}
          <div
            ref={sliderRef}
            className="w-[80vw] h-[75vh] xl:w-[80vw] lg:h-[70vh] z-[100]"
          >
            <Swiper
              navigation={true}
              modules={[Navigation]}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] object-contain"
                  src={slide1}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] object-contain"
                  src={slide2}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] object-contain"
                  src={slide3}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] object-contain"
                  src={slide4}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] object-contain"
                  src={slide5}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] object-contain"
                  src={slide6}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] object-contain"
                  src={slide7}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}

      {showSliderCommercial && (
        <div className="fixed inset-0  flex items-center justify-center bg-[rgba(0,0,0,0.5)] z-[20]">
          {/* Ref added to track clicks inside the slider */}
          <div
            ref={sliderRef}
            className="w-[80vw] md:w-[90%] h-[75vh] lg:h-[fit-content] z-[100]"
          >
            <Swiper
              navigation={true}
              modules={[Navigation]}
              centeredSlides={true}
              slidesPerView={1}
              loop={true}
              className="mySwiper"
            >
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] images"
                  src={slide8}
                />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  alt=""
                  className="w-[100%] h-[100%] images"
                  src={slide9}
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      )}

      <div
        className={`fixed inset-0 bg-bg-main transform ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out bg-[#a0a0a0] `}
      >
        <div className="flex flex-col items-center justify-center h-full gap-10 p-5">
          <div className="flex text-[20px] flex-col items-center space-y-6">
            {/* Town Houses */}
            <div className="relative text-center">
              <Link
                onClick={() => setShowTownHouses(!showTownHouses)}
                className="hover:text-[#9b2e2e] text-white font-[700] cairo-font"
              >
                TOWN HOUSES
              </Link>
              {showTownHouses && (
                <div className="flex flex-col py-[3%] text-[18px] xl:text-[12px] relative">
                  <Link
                    onClick={() => {
                      handleIframeToggle(
                        "https://my.matterport.com/show/?m=dHmdWsQoY6o"
                      );
                      setMenuOpen(false); // Close the navbar
                    }}
                    className="text-white hover:text-[#9b2e2e]"
                  >
                    3D Tour
                  </Link>
                  <Link
                    className="text-white hover:text-[#9b2e2e] pt-[3%]"
                    onClick={() => {
                      handleVideo(vid2);
                      setMenuOpen(false); // Close the navbar
                    }}
                  >
                    Video
                  </Link>
                </div>
              )}
            </div>

            {/* Villas */}
            <div className="relative text-center">
              <Link
                onClick={() => setShowVillas(!showVillas)}
                className="hover:text-[#9b2e2e] text-white font-[700] cairo-font"
              >
                VILLAS
              </Link>
              {showVillas && (
                <div className="flex flex-col py-[3%] text-[18px] xl:text-[12px] relative">
                  <Link
                    onClick={() => {
                      handleIframeToggle(
                        "https://my.matterport.com/show/?m=ZWKSeA4bvZu"
                      );
                      setMenuOpen(false); // Close the navbar
                    }}
                    className="text-white hover:text-[#9b2e2e]"
                  >
                    3D Tour
                  </Link>
                  <Link
                    className="text-white hover:text-[#9b2e2e] pt-[3%]"
                    onClick={() => {
                      handleVideo(vid1);
                      setMenuOpen(false); // Close the navbar
                    }}
                  >
                    Video
                  </Link>
                </div>
              )}
            </div>

            {/* Residential */}
            <div className="relative text-center">
              <Link
                onClick={() => setShowResidential(!showResidential)}
                className="hover:text-[#9b2e2e] text-white font-[700] cairo-font"
              >
                RESIDENTIAL
              </Link>
              {showResidential && (
                <div className="flex flex-col py-[3%] text-[18px] xl:text-[12px] relative">
                  <Link
                    onClick={() => {
                      handleIframeToggle(
                        "https://my.matterport.com/show/?m=QhyJe1Cnzez"
                      );
                      setMenuOpen(false); // Close the navbar
                    }}
                    className="text-white hover:text-[#9b2e2e]"
                  >
                    3D Tour
                  </Link>
                  <Link
                    className="text-white hover:text-[#9b2e2e] pt-[3%]"
                    onClick={() => {
                      handleVideo(vid3);
                      setMenuOpen(false); // Close the navbar
                    }}
                  >
                    Video
                  </Link>
                </div>
              )}
            </div>

            {/* Commercial */}
            <Link
              onClick={() => {
                handleShowSlideCommercial();
                setMenuOpen(false); // Close the navbar
              }}
              className="hover:text-[#9b2e2e] cairo-font text-white font-[700]"
            >
              COMMERCIAL
            </Link>

            {/* Renders */}
            <Link
              onClick={() => {
                handleShowSlide();
                setMenuOpen(false); // Close the navbar
              }}
              className="hover:text-[#9b2e2e] cairo-font text-white font-[700]"
            >
              RENDERS
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sarooj;
